@import "./0_mediaquery";
@import "./variables";

.pricing {
  padding: 4em 0 4em 0;
  min-height: 50vh;

  .container {
    max-width: 85%;
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3em;

    .item {
      padding: 2em;
      box-shadow: 1px 1px 5px #60606080;
      border-radius: 12px;
      text-align: center;

      h2 {
        color: $primary;
        margin: 10px 0 10px 0;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: small;
        line-height: 40px;
        color: #22343d;
      }

      button {
        border: 1px solid $primary;
        padding: 8px 4em 8px 4em;
        background: $primary;
        border-radius: 8px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: medium;
        color: white;
        margin: 1em 0 1em 0;
        cursor: pointer;
      }
      button:hover {
        background-color: transparent;
        color: $primary;
      }

      .div {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
        width: fit-content;
        margin: 1em auto 1em auto;

        h1 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: x-large;
          height: 100%;
          text-align: center;
          letter-spacing: 0.2px;
          color: $primary;
        }

        h4 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: large;
          letter-spacing: 0.1px;
          color: $primary;
          text-align: left;
        }

        h6 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: small;
          letter-spacing: 0.1px;
          color: #afafaf;
          text-align: left;
        }
      }
    }
    .item:nth-child(2) {
      background-color: $primary;

      * {
        color: white !important;
      }

      button {
        background-color: white;
        color: $primary !important;
        border: 1px solid white;
      }
      button:hover {
        background-color: transparent;
        color: white !important;
      }
    }
  }
}

.advert {
  padding: 4em 0 4em 0;
  min-height: 50vh;

  .container {
    max-width: 70%;
    width: 100%;
    padding: 2em;
    background-color: $primary;
    color: white;
    margin: auto;
    border-radius: 30px;

    .div2equal {
      width: 100% !important;
    }

    .left {
      height: fit-content;
      margin: auto 0 auto 0;

      h1 {
        font-size: xx-large;
        line-height: 1.3;
        max-width: 90%;
      }
      button {
        border: 1px solid white;
        padding: 8px 4em 8px 4em;
        background: #ffffff;
        border-radius: 8px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: medium;
        line-height: 30px;
        color: #22343d;
        margin: 1em 0 1em 0;
        cursor: pointer;
      }
      button:hover {
        background-color: transparent;
        color: white;
      }
    }

    img {
      object-fit: contain;
      width: 100% !important;
    }
  }
}

.submitbutton {
  display: flex;
  justify-content: center;
  margin: 2em auto 2em auto;

  .cta {
    display: flex;
    padding: 5px 45px;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-size: 40px;
    color: white;
    background: $primary;
    transition: 1s;
    box-shadow: 6px 6px 0 black;
    transform: skewX(-15deg);

    @include maxwidth(tablet) {
      font-size: 20px;
      padding-top: 10px;
    }
    @include maxwidth(mobile) {
      font-size: 20px;
      line-height: 50px;
    }
  }

  .cta:focus {
    outline: none;
  }

  .cta:hover {
    transition: 0.5s;
    box-shadow: 10px 10px 0 #fbc638;
  }

  .cta span:nth-child(2) {
    transition: 0.5s;
    margin-right: 0px;
  }

  .cta:hover span:nth-child(2) {
    transition: 0.5s;
    margin-right: 45px;
  }

  span {
    transform: skewX(15deg);
  }

  span:nth-child(2) {
    width: 20px;
    margin-left: 30px;
    position: relative;
    top: 12%;
  }

  /**************SVG****************/

  path.one {
    transition: 0.4s;
    transform: translateX(-60%);
  }

  path.two {
    transition: 0.5s;
    transform: translateX(-30%);
  }

  .cta:hover path.three {
    animation: color_anim 1s infinite 0.2s;
  }

  .cta:hover path.one {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.6s;
  }

  .cta:hover path.two {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.4s;
  }

  /* SVG animations */

  @keyframes color_anim {
    0% {
      fill: white;
    }
    50% {
      fill: #fbc638;
    }
    100% {
      fill: white;
    }
  }
}

.faqs {
  padding: 4em 0 4em 0;

  .container {
    max-width: 70%;
    width: 100%;
    margin: auto;

    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #22343d;
      text-align: center;
    }

    h3 {
      text-align: center;
      font-weight: 400;
      margin: 1em 0 1em 0;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: medium;
      line-height: 32px;
      color: #22343d;
      margin: 1em 0 1em 0;
    }

    .item {
      margin: 1em 0 1em;
      div {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        padding: 10px 0 10px 0;
        cursor: pointer;

        .icon {
          height: 24px;
          width: 24px;
          border-radius: 24px;
          line-height: 24px;
          background-color: $primary;
          color: white;
          text-align: center;
          font-size: 20px;
          font-weight: 900;
        }

        h4 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #22343d;
        }
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #22343d;
        }
      }
    }
  }
}

.loading {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;

  .container {
    width: fit-content;
    height: fit-content;
    margin: auto;
  }

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $primary;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
}

@include maxwidth(mobile) {
  .pricing {
    .container {
      grid-template-columns: 1fr !important;
    }
  }
  .advert .container {
    max-width: 85vw !important;

    h1 {
      font-size: x-large !important;
      max-width: 100% !important;
    }
  }
}

@include maxwidth(tablet) {
  .pricing {
    .container {
      grid-template-columns: 1fr !important;
    }
  }
  .advert .container {
    max-width: 85vw !important;

    h1 {
      font-size: xx-large !important;
      max-width: 100% !important;
    }
  }
}
