@import "@fontsource/roboto/300.css";
@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto/700.css";

@import "./Styles/0_mediaquery";
@import "./Styles/variables";

html {
  scroll-behavior: smooth;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "roboto";
}
body {
  background-color: #f5f5f5;
  background-position: top;
  background-size: 100% auto;
  background-repeat: repeat;
  background-attachment: fixed;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  transition: background-color 0.5s ease-in-out;

  .contacts {
    padding: 0 2em;
    background-color: $primary;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    transition: display 0.5s ease-in-out;
    .ccontainer {
      display: grid;
      grid-template-columns: repeat(4, auto);
      gap: 1em;
      width: fit-content;
      margin-left: auto;

      .social {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        padding: 2px 5px 2px 0;

        a {
          border-left: none;
          font-size: large;
        }
      }

      a {
        font-size: small;
        text-decoration: none;
        color: white;
        padding: 2px 0 2px 1em;
        display: block;
        margin: auto;
        border-left: 1px solid white;
      }
    }
  }
  .hcontainer {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1em;
    max-width: 85vw;
    width: 100%;
    margin: auto;

    .left {
      padding: 5px 0;
      img {
        height: 44px;
        object-fit: contain;
        display: block;
        margin: auto 0;
      }
    }
    .right {
      display: flex;
      align-items: center;
      height: 100%;

      .wrapper {
        display: grid;
        grid-template-columns: repeat(7, auto);
        gap: 1em;
        color: white;
        width: fit-content;
        margin: auto 0 auto auto;

        height: 100%;

        a {
          text-decoration: none;
          color: white;
          text-transform: uppercase;
          cursor: pointer;
          font-size: small;
          display: block;
          margin: auto;
        }
        a:hover {
          color: $accent;
        }

        .active {
          color: $accent;
        }

        div {
          border-left: 1px solid rgba(255, 255, 255, 0.5);
          border-right: 1px solid rgba(255, 255, 255, 0.5);
          padding: 0 1em;
          height: 100%;
          display: flex;
          align-items: center;

          a {
            display: block;
            margin: auto;
          }
        }
      }
    }

    .mob {
      display: none;
      place-content: center;
      color: white;

      i {
        font-size: large;
        width: fit-content;
        text-align: center;
        color: white;
        margin: auto 0 auto auto;
        display: block;
        height: 30px;
        line-height: 30px;
      }
    }

    @include maxwidth(mobile) {
      grid-template-columns: 1fr auto;
      .right {
        display: none;
      }

      .mob {
        display: grid;
        order: 0;
      }
    }
    @include maxwidth(tablet) {
      grid-template-columns: 1fr auto;
      .right {
        display: none;
      }

      .mob {
        display: grid;
        order: 0;
      }
    }
  }
}

.headermodal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $secondary;
  color: white;
  z-index: 99999;
  .container {
    padding: 2em;

    .left {
      margin: 1em auto 3em auto;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      img {
        height: 44px;
      }

      a {
        font-size: medium;
      }

      i {
        text-align: center;
        color: $primary;
        margin: auto 0 auto auto;
        display: block;
        font-size: x-large;
        width: fit-content;
        height: fit-content;
      }
    }

    .right {
      .wrapper {
        width: fit-content;
        margin: 10px auto 10px auto;
        display: grid;
        grid-template-rows: repeat(5, auto);
        gap: 1em;

        a {
          text-decoration: none;
          line-height: 30px;
          color: white;
          padding: 0 2em 0 2em;
          cursor: pointer;
          text-align: center;
          width: 100%;
          font-size: medium !important;
        }
        a:hover {
          color: $primary;
        }

        .active {
          color: $primary;
        }

        .btn {
          background-color: $primary;
          padding: 0 1em 0 1em;
          border-radius: 4px;
          line-height: 30px;
          color: white;
          border: 1px solid $primary;
          cursor: pointer;
        }
        .btn:hover {
          background-color: white;
          color: $primary;
        }
      }
    }
  }
}

.footer {
  background-color: $secondary;
  padding: 3em 0;

  .container {
    max-width: 85vw;
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2em;
    color: white;

    h4 {
      font-size: large;
      font-weight: 400;
      margin-bottom: 1em;
    }

    a {
      text-decoration: none;
      font-size: small;
      color: white;
      cursor: pointer;
      display: block;
      margin: 5px 0;
      transition: color 0.5s ease-in-out;
    }
    a:hover {
      color: $accent;
    }

    button {
      border: 2px solid $primary;
      padding: 10px 3em;
      border-radius: 8px;
      color: $primary;
      cursor: pointer;
      margin-top: 1em;
      min-width: 200px;
      background-color: transparent;
      transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out,
        border-radius 0.5s ease-in-out;
    }
    button:hover {
      background-color: $primary;
      color: white;
      border-radius: 0;
    }

    input {
      display: block;
      padding: 8px 1em;
      background-color: transparent;
      border: 1px solid white;
      color: white;
      border-radius: 5px;
      margin: 1em 0;
      max-width: 30vw;
      width: 100%;
      outline: none;
    }
  }
  @include maxwidth(mobile) {
    .container {
      grid-template-columns: 1fr !important;
    }
  }
  @include maxwidth(tablet) {
    .container {
      grid-template-columns: 1fr !important;
    }
  }
}

.div2equal {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
}

.div3equal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
}

@include maxwidth(mobile) {
  body {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .contacts {
    display: none;
  }
  .hcontainer {
    padding: 10px 0 10px 0;

    img {
      height: 32px !important;
    }
  }
  .div2equal,
  .div3equal {
    grid-template-columns: 1fr !important;
  }

  h1,
  h2 {
    max-width: 85% !important;
  }
  .main {
    img {
      margin-bottom: 4em !important;
    }
  }

  .testimonials {
    .container {
      max-width: 85vw !important;

      .arrange {
        grid-template-columns: 1fr !important;
      }
    }
  }
}

@include maxwidth(tablet) {
  .hcontainer {
    padding: 10px 0 10px 0;
    img {
      height: 32px !important;
    }
  }
  .contacts {
    display: none;
  }
  .div2equal,
  .div3equal {
    grid-template-columns: 1fr !important;
  }

  h1,
  h2,
  p {
    max-width: 85% !important;
  }
}
