@import "./0_mediaquery";
@import "./variables";

.landing {
  background-image: url("../assets/images/bg1.jpg");
  background-position: top;
  background-size: auto 100%;
  background-repeat: repeat;
  background-attachment: fixed;

  .hero {
    background-color: rgba($color: #000000, $alpha: 0.3);
    height: 95vh;
    width: 100%;
    display: grid;
    place-content: center;

    .center {
      color: white;

      h1 {
        font-weight: 500;
        font-size: 44px;
        max-width: 50vw;
        margin: 1em auto;
        display: block;
        text-align: center;
        opacity: 0;
        transform: translateY(20px);
        animation: slideUp 0.5s ease-in-out 0.5s forwards;
      }

      h2 {
        font-weight: 200;
        font-size: x-large;
        max-width: 50vw;
        margin: 1em auto;
        display: block;
        text-align: center;
        opacity: 0;
        transform: translateY(20px);
        animation: slideUp 0.5s ease-in-out 1s forwards;
      }

      button {
        cursor: pointer;
        display: block;
        margin: 2em auto;
        background-color: $primary;
        padding: 10px 3em;
        border: 1px solid $primary;
        border-radius: 8px;
        color: white;
        transition: background-color 0.5s ease-in-out, border 0.5s ease-in-out,
          border-radius 0.5s ease-in-out;
        opacity: 0;
        transform: translateY(20px);
        animation: slideUp 0.5s ease-in-out 2s forwards;
      }
      button:hover {
        background-color: transparent;
        border: 1px solid white;
        border-radius: 0px;
      }
    }
  }

  .main {
    background-color: white;
    padding: 2em 0;

    .div2equal {
      max-width: 85vw;
      width: 100%;
      margin: auto;
      padding: 4em 0;
    }
    .div2equal:hover {
      img {
        filter: drop-shadow(0px 60px 10px rgba(0, 0, 0, 0.3));
      }
    }

    .l1 {
      height: 100%;
      display: grid;
      place-content: center;

      img {
        max-width: 80%;
        width: 100%;
        display: block;
        margin: auto;
        transition: filter 1s ease-in-out;
        filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.3));
      }
    }
    .l2 {
      min-height: 50vh;
      h1 {
        font-weight: 200;
        font-size: 44px;
        display: block;
        opacity: 0;
        color: $primary;
        transform: translateY(20px);
        animation: slideUp 0.5s ease-in-out 0.5s forwards;
      }

      h2 {
        font-weight: 500;
        font-size: medium;
        margin: 1em 0;
        display: block;
        color: $secondary;
        opacity: 0;
        line-height: 2;
        transform: translateY(20px);
        animation: slideUp 0.5s ease-in-out 1s forwards;
      }

      p {
        font-size: small;
        opacity: 0;
        line-height: 2.5;
        transform: translateY(20px);
        animation: slideUp 0.5s ease-in-out 1.5s forwards;
      }
      button {
        border: 2px solid $primary;
        padding: 10px 3em;
        border-radius: 8px;
        color: $primary;
        cursor: pointer;
        margin-top: 1em;
        min-width: 200px;
        background-color: transparent;
        transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out,
          border-radius 0.5s ease-in-out;
      }
      button:hover {
        background-color: $primary;
        color: white;
        border-radius: 0;
      }
    }
  }

  .maintenance {
    background-color: #f8f8f8;
    padding: 3em 0;
    .container {
      max-width: 70vw;
      width: 100%;
      margin: auto;

      h1 {
        font-weight: 200;
        font-size: 44px;
        display: block;
        opacity: 0;
        text-align: center;
        color: $primary;
        transform: translateY(20px);
        animation: slideUp 0.5s ease-in-out 0.5s forwards;
      }

      h2 {
        font-weight: 500;
        font-size: medium;
        margin: 1em 0;
        display: block;
        text-align: center;
        color: $secondary;
        opacity: 0;
        line-height: 2;
        transform: translateY(20px);
        animation: slideUp 0.5s ease-in-out 1s forwards;
      }

      img {
        max-width: 70%;
        width: 100%;
        object-fit: contain;
        display: block;
        margin: auto;
      }
    }
  }

  .other_services {
    background-color: white;
    padding: 1em 0;

    h1 {
      font-weight: 200;
      font-size: 44px;
      display: block;
      opacity: 0;
      text-align: center;
      color: $primary;
      transform: translateY(20px);
      animation: slideUp 0.5s ease-in-out 0.5s forwards;
    }

    .div3equal {
      max-width: 85vw;
      width: 100%;
      margin: auto;
      padding: 4em 0;
    }
    .divauto1:hover {
      border: 2px solid $primary;
      img {
        filter: drop-shadow(0px 20px 10px rgba(0, 0, 0, 0.3));
      }
    }

    .divauto1 {
      display: grid;
      grid-template-columns: 54px 1fr;
      gap: 1em;
      padding: 1em;
      border: 2px solid transparent;
      transition: border 1s ease-in-out;
      cursor: pointer;
    }

    .l1 {
      height: 100%;

      img {
        max-width: 80%;
        width: 100%;
        display: block;
        margin-bottom: auto;
        transition: filter 1s ease-in-out;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.3));
      }
    }
    .l2 {
      h4 {
        font-weight: 200;
        font-size: large;
        display: block;
        opacity: 0;
        margin-bottom: 10px;
        color: $primary;
        transform: translateY(20px);
        animation: slideUp 0.5s ease-in-out 0.5s forwards;
      }

      p {
        font-size: small;
        opacity: 0;
        line-height: 1.7;
        transform: translateY(20px);
        animation: slideUp 0.5s ease-in-out 1s forwards;
      }
      button {
        border: 2px solid $primary;
        padding: 10px 3em;
        border-radius: 8px;
        color: $primary;
        cursor: pointer;
        margin-top: 1em;
        min-width: 200px;
        background-color: transparent;
        transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out,
          border-radius 0.5s ease-in-out;
      }
      button:hover {
        background-color: $primary;
        color: white;
        border-radius: 0;
      }
    }
  }

  .pitch {
    background-color: rgba($color: #000000, $alpha: 0.5);
    padding: 1em 0;
    display: flex;
    align-items: center;
    min-height: 80vh;

    .l2 {
      max-width: 85vw;
      width: 100%;
      margin: auto;

      input {
        display: block;
        padding: 8px 1em;
        background-color: transparent;
        border: 1px solid white;
        color: white;
        border-radius: 5px;
        margin: 1em 0;
        max-width: 30vw;
        width: 100%;
        outline: none;
      }

      h1 {
        max-width: 50%;
        font-weight: 200;
        font-size: 44px;
        display: block;
        opacity: 0;
        color: white;
        transform: translateY(20px);
        animation: slideUp 0.5s ease-in-out 0.5s forwards;
      }

      p {
        max-width: 50%;
        font-size: medium;
        opacity: 0;
        color: white;
        margin: 1em 0;
        line-height: 1.5;
        transform: translateY(20px);
        animation: slideUp 0.5s ease-in-out 1s forwards;
      }
      button {
        border: 2px solid $primary;
        padding: 10px 3em;
        border-radius: 8px;
        color: $primary;
        cursor: pointer;
        margin-top: 1em;
        min-width: 200px;
        background-color: transparent;
        transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out,
          border-radius 0.5s ease-in-out;
      }
      button:hover {
        background-color: $primary;
        color: white;
        border-radius: 0;
      }
    }
  }

  .testimonials {
    padding: 3em 0;
    background-color: white;

    .container {
      max-width: 70vw;
      width: 100%;
      margin: auto;

      h1 {
        font-weight: 200;
        font-size: 44px;
        display: block;
        text-align: center;
        opacity: 0;
        color: $primary;
        transform: translateY(20px);
        animation: slideUp 0.5s ease-in-out 0.5s forwards;
      }

      .arrange {
        display: grid;
        grid-template-columns: 24px 1fr 24px;
        gap: 3em;
        min-height: 50vh;
        overflow: hidden;

        .fa {
          width: 24px;
          height: 24px;
          display: block;
          margin: auto;
          cursor: pointer;
        }
        .fa:hover {
          color: $primary;
        }
      }

      .item-entry {
        margin-top: 2em;
        transform: translateX(100%);
        animation: slideLeft 1s ease forwards;

        p {
          line-height: 2;
          text-align: center;
          margin: 1em 0;
        }

        h4 {
          color: $primary;
          text-align: center;
          font-weight: 500;
        }
      }
      .item-exit {
        margin-top: 2em;
        transform: translateX(0%);
        animation: slideLeftExit 1s ease forwards;

        p {
          line-height: 2;
          text-align: center;
          margin: 1em 0;
        }

        h4 {
          color: $primary;
          text-align: center;
          font-weight: 500;
        }
      }
    }
  }

  .landing_hero {
    height: 80vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;

    .nhero {
      height: 100%;
      position: relative;
      background-color: rgba($color: #000000, $alpha: 0.5);
      z-index: 999;

      .slideshow-container {
        position: relative;
        height: 100%;
      }

      .slideshow-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;

        img {
          max-height: 100%;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .content {
          position: absolute;
          top: 0;
          left: 7.5vw;
          right: 50vw;
          height: 100%;
          display: flex;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.7);
          padding: 2em;
        }

        .left.animation-active {
          opacity: 1;
          transform: translateY(0);
        }

        .left {
          display: grid;
          grid-template-rows: auto 1fr;
          gap: 1em;
          text-align: center;
          color: white;
          height: 100%;
          padding: 2em 0 2em 0 !important;
          opacity: 0;
          transform: translateY(50px);
          transition: transform 0.5s ease;

          p {
            padding: 0 0 64px 0;
            position: relative;
            text-align: left !important;
            width: fit-content;
            font-weight: 600;
          }

          p::before {
            content: "";
            position: absolute;
            left: 0%;
            bottom: 16px;
            max-width: 150px;
            width: 100%;
            height: 44px;
            transform: skew(-45deg) translateX(-50%);
            background: $primary;
            z-index: -1;
          }

          h1 {
            margin: auto;
            font-family: "Ubuntu", sans-serif;
            font-size: xx-large;
            color: white;
            text-align: center;
            letter-spacing: 5px;
            text-transform: uppercase;
          }

          p {
            font-size: x-large !important;
            color: white;
          }

          button {
            background-color: orange;
            color: white;
            border: 1px solid orange;
            border-radius: 5px;
            padding: 10px 1em 10px 1em;
            cursor: pointer !important;
            margin: auto;
            height: fit-content;
          }
          button:hover {
            border: 1px solid orange;
            color: orange !important;
            background-color: transparent !important;
          }
        }
      }

      .slideshow-image.active {
        opacity: 1;
      }
    }

    @include maxwidth(mobile) {
      .content {
        right: 7.5vw !important;
        .left {
          height: fit-content !important;
          grid-template-rows: auto 30vh auto !important;

          p {
            font-size: x-small !important;
            height: fit-content !important;
          }

          h1 {
            font-size: x-large !important;
          }
        }
      }
    }
    @include maxwidth(tablet) {
      .content {
        right: 7.5vw !important;
        .left {
          height: fit-content !important;
          grid-template-rows: auto 30vh auto !important;

          p {
            font-size: x-small !important;
            height: fit-content !important;
          }

          h1 {
            font-size: x-large !important;
          }
        }
      }
    }
  }

  .myintro {
    text-align: center;
    padding: 2em !important;

    .flip-card {
      perspective: 1000px;
      position: relative;
      height: 50vh;
    }

    .flip-card-inner {
      width: 100%;
      height: 100%;
      transition: transform 0.6s;
      transform-style: preserve-3d;
    }

    .flip-card:hover .flip-card-inner {
      transform: rotateY(180deg);
    }

    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden !important;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 1px 1px 5px #60606030;
      color: white;

      h3 {
        font-size: x-large;
        font-weight: 600;
        color: $secondary;
      }

      p {
        padding: 10px;
        font-weight: 400;
        font-size: small !important;
        line-height: 2;
      }
    }

    .flip-card-front {
      background: $primary !important;
      display: grid;
      grid-template-rows: 1fr auto 1fr;
      height: 100%;
      gap: 10px;
      padding: 10px;
      img {
        max-height: 74px;
        height: 100%;
        margin: auto;
        display: block;
        object-fit: contain;
      }
    }

    .flip-card-back {
      background: $secondary !important;
      transform: rotateY(180deg);
      display: flex;
      align-items: center;
    }

    .hero-content {
      max-width: 85vw;
      width: 100%;
      margin: 2em auto 2em auto;
      gap: 3em !important;

      h1 {
        font-size: xx-large;
        padding: 1em;
      }

      p {
        font-size: large;
        padding: 1em;
      }

      .service-card {
        background-color: $primary;
        padding: 4em 1em;
        border-radius: 5px;
        color: white;
      }
      .service-card:hover {
        background-color: $secondary;
      }
    }
  }

  .finnai {
    .fcontainer {
      margin: auto;

      h1 {
        text-align: center;
        font-size: xx-large;
        color: $primary;
        margin: 2em;
      }

      .item {
        position: relative;
        min-height: 50vh !important;
        animation: opacity 2s ease-in-out;

        .bg {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: -1;
          display: none;
          background-color: $secondary;
          -webkit-transition: display 2s;
        }

        .div2equal {
          padding: 2em 0 2em 0;
          max-width: 85vw;
          width: 100%;
          margin: auto;
          z-index: 10;
        }

        .img {
          width: 100%;
          overflow: hidden;

          img {
            max-width: 80%;
            width: 100%;
            object-fit: contain;
            margin: auto;
            display: block;
          }
        }

        .content {
          height: fit-content;
          margin: auto;

          h2 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: xx-large;
            line-height: 36px;
            text-align: left;
            color: #22343d;
            margin: 1em 0 1em 0;
          }

          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: medium;
            line-height: 28px;
            color: #22343d;
          }

          button {
            margin: 2em auto 2em 1px !important;
            background-color: white;
            color: $primary !important;
            padding: 1em 2em 1em 2em !important;
            min-width: 150px;
            border: 1px solid $primary;
            border-radius: 5px !important;
            cursor: pointer;
          }
          button:hover {
            background-color: $primary;
            color: white !important;
          }
        }
      }
      .item:nth-child(3),
      .item:nth-child(5),
      .item:nth-child(7) {
        background-image: linear-gradient(
          to bottom,
          rgba($color: #f5f5f5, $alpha: 1),
          rgba($color: #f5f5f5, $alpha: 0)
        );
        .img {
          order: 1;
        }
        .content {
          order: 0;
        }
      }
      .item:hover {
        background-color: rgba($color: #000000, $alpha: 0.5) !important;
        background-image: none !important;

        .bg {
          display: block;
          background-color: black;
          animation: opacity 2s ease-in-out;
          background-position: top;
          background-size: cover;
          background-repeat: no-repeat;
          -webkit-transition: opacity 2s;
        }
        * {
          color: white !important;
        }

        .content {
          animation: zoom 1s;
        }
      }
    }

    @include maxwidth(mobile) {
      .fcontainer {
        img {
          order: 0 !important;
        }
        p {
          width: 100% !important;
        }
        .content {
          order: 1 !important;
          margin: 2em 0 2em 0 !important;
        }
      }
    }
  }

  @include maxwidth(mobile) {
    .hero {
      p {
        font-size: medium !important;
      }
      .mySwiper {
        max-width: 85vw !important;
      }
    }

    .vision {
      .container {
        grid-template-columns: 1fr !important;
      }
    }

    .hero div {
      background-position: right !important;
    }

    .intro {
      h1 {
        color: $myBlue;
        margin-top: 1em !important;
        margin-bottom: 0.5em !important;
        font-size: 24pt;
      }

      p {
        max-width: 85vw !important;
      }
    }
  }
  @include maxwidth(tablet) {
    .hero {
      p {
        font-size: medium !important;
      }
      .mySwiper {
        max-width: 85vw !important;
      }
    }

    .vision {
      .container {
        grid-template-columns: 1fr !important;
      }
    }

    .finnai {
      img {
        width: 100% !important;
        height: max-content !important;
        order: 0 !important;
        margin: 3em 0 0 0 !important;
      }
      p {
        width: 100% !important;
      }
      .content {
        order: 1 !important;
        margin: 2em 0 2em 0 !important;
      }
    }
    .hero div {
      background-position: right !important;
    }

    .intro {
      h1 {
        color: $myBlue;
        margin-top: 1em !important;
        margin-bottom: 0.5em !important;
        font-size: 24pt;
      }

      p {
        width: 85vw !important;
      }
    }
  }

  @keyframes slideLeftExit {
    from {
      transform: translateX(00%); /* Starting position */
    }
    to {
      transform: translateX(-100%); /* Move left by 100% of the box's width */
    }
  }

  @keyframes slideLeft {
    from {
      transform: translateX(100%); /* Starting position */
    }
    to {
      transform: translateX(0%); /* Move left by 100% of the box's width */
    }
  }

  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
