@import "./0_mediaquery";
@import "./variables";

.contact {
  background-color: white;
  padding-top: 64px;
 // margin-top: 50px;
  .hero {
    padding: 10px 0 2em 0;
    background-image: linear-gradient(
      to bottom,
      rgba($color: #f5f5f5, $alpha: 1),
      rgba($color: #f5f5f5, $alpha: 0)
    );

    .container {
      max-width: 85%;
      width: 100%;
      margin: auto;

      .map {
        width: 100%;
        height: 70vh !important;
        box-shadow: 10px 10px 0px $primary;
        border: 1px solid #d0d0d0;
        margin-bottom: 3em;
        display: grid;
        grid-template-rows: auto 1fr;
      }

      h1 {
        text-align: center;
        margin-bottom: 1em !important;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: medium;
        color: black;
        margin: 12px 0 12px 0;
        text-align: center;
      }

      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .vision {
    background-color: white;
    padding: 4em 0 4em 0;

    h1 {
      color: $myBlue;
      font-size: xx-large;
      text-align: center;
      max-width: 85%;
      width: 100%;
      margin: auto;
    }

    p {
      text-align: center;
      margin: 1em auto 3em auto;
      font-size: medium;
      max-width: 85%;
      width: 100%;
      margin: auto;
    }

    .container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2em;
      max-width: 85%;
      width: 100%;
      margin: auto;

      .item {
        border: 1px solid #dedede;
        border-radius: 12px;
        padding: 2em;
        min-height: 50vh;
        display: grid;
        grid-template-rows: auto 1fr;

        h2 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: xx-large;
          margin-bottom: 1em;
          text-align: center;
          letter-spacing: 0.1px;
          color: black;
        }

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: medium;
          line-height: 27px;
          /* or 169% */
          text-align: center;
          letter-spacing: 0.2px;
          color: black;
        }
      }

      .item:hover {
        border: 1px solid $primary;

        h2 {
          color: $primary;
        }
      }

      @include maxwidth(mobile) {
        grid-template-columns: 1fr !important;
      }
      @include maxwidth(tablet) {
        grid-template-columns: 1fr !important;
      }
    }
  }

  .conct {
    padding: 1em 0 1em 0;

    .container {
      max-width: 50%;
      width: 100%;
      margin: auto;
      text-align: center;

      form {
        position: relative;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: medium;
        line-height: 32px;
        color: black;
        margin: 5px 0 5px 0;
        text-align: center;
      }

      .social {
        margin: 3em 0 3em 0;
        gap: 1em !important;

        img {
          margin: 10px;
        }
      }

      .form {
        padding: 3em 0 3em 0;

        .input {
          margin: 1.5em 0 1.5em 0;

          h4 {
            font-size: medium;
            text-align: left;
            margin-bottom: 10px;
            font-weight: 500;
            text-transform: capitalize;
          }

          input {
            background: #f5f5f5;
            border: 1px solid $secondary;
            border-radius: 8px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: medium;
            letter-spacing: 0.1px;
            color: black;
            flex: none;
            order: 0;
            flex-grow: 0;
            width: 100%;
            padding: 1em;
          }
          textarea {
            padding: 1em;
            width: 100%;
            background: #f5f5f5;
            border: 1px solid $secondary;
            border-radius: 8px;
          }
        }
      }

      @include maxwidth(tablet) {
        max-width: 70% !important;
      }
      @include maxwidth(mobile) {
        max-width: 85% !important;
      }
    }
  }

  .ahero {
    height: 50vh;
    background-image: url("../assets/images/about.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: baseline;

    .wrapper {
      background: url("../assets/images/about.png"), #111111;
      color: #eee;
      height: 100%;
      min-width: 360px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      perspective: 1000px;
      perspective-origin: 50% 50%;
    }

    .wrapper:hover {
      animation: scroll 70s linear infinite;
    }

    @keyframes scroll {
      100% {
        background-position: 0px -3000px;
      }
    }

    @media (prefers-reduced-motion) {
      .wrapper {
        animation: scroll 200s linear infinite;
      }
    }

    @media (min-width: 670px) {
      .title {
        font-size: 5rem;
      }
    }

    .container {
      max-width: 85vw;
      width: 100%;
      margin: auto auto 0 auto;
      height: fit-content;
      background-color: rgba($color: $secondary, $alpha: 0.5);
      padding: 1em;

      p,
      h1 {
        display: block;
        margin: 1em auto 1em auto;
        text-align: center;
        color: white;
        animation: zoom 2s ease-in;
      }
    }
  }
}
@keyframes zoom {
  0% {
    scale: 0.8;
  }
  100% {
    scale: 1;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
